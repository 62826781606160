/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPriceFromSize } from 'utils/productUtils';
import propTypes from 'prop-types';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import { useCookies } from 'react-cookie';
import SEO from 'components/SEO';
import Price from 'components/Price';
import SizeChartModal from 'components/SizeChartModal';
import { ModalConductor } from '@springforcreators/propel-ui';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import { tiktok } from 'utils/tracking';
import './ListingDetails.scss';
import ProductDetailTabs from 'components/ProductDetailTabs';
import has from 'lodash/has';
import { PRODUCT_DETAIL, pushEvent } from 'utils/tracking/gtm';
import { setActiveModal } from 'redux/actions';
import PhysicalReturnDetails from 'components/DeliveryReturns/PhysicalReturnDetails';
import HolidayMessage from 'components/DeliveryReturns/HolidayMessage';
import removeCurrencySymbol from '../../lib';
import ListingForm from './ListingForm';

export function ListingDetailsWrapper(WrappedComponent) {
  const BaseComponent = (props) => {
    const { setClass } = useGlobalProps();

    const {
      listing,
      listingProduct,
      productDetails,
      onProductSizeChange,
      onProductVariationChange,
      activeSizeIndex,
      activeVariationIndex,
      activeProductIndex,
      cartData,
      location,
      stores,
      storeName,
      currency,
      locale,
      inventory,
      onFormSubmit,
      dispatchSetActiveModal,
      activeModal
    } = props;

    const { title, description, itemGroupId } = listingProduct;

    const variant = has(listingProduct, 'primaryProduct') &&
      get(listingProduct.primaryProduct, activeVariationIndex) ?
      get(listingProduct.primaryProduct, activeVariationIndex, {}) :
      get(listingProduct.variations, activeVariationIndex, {});

    const primaryProduct = has(listingProduct, 'primaryProduct') &&
      get(listingProduct.primaryProduct, activeProductIndex) ?
      get(listingProduct.primaryProduct, activeProductIndex) :
      listingProduct;

    const [otherProductClicked, setOtherProductClicked] = useState(false);

    // Check if sku is already in cart
    const productType = get(primaryProduct, 'productType');
    const sizeId = get(variant, `sizes[${activeSizeIndex}].id`);
    const colorId = get(variant, 'variationId');
    const list = get(location, 'state.list', 'PDP');
    const productId = get(primaryProduct, 'productId');
    const key = `${listing?.listingId}-${colorId}-${sizeId}`;
    const inventoryCount = get(inventory, key);

    const eventProduct = {
      name: title,
      id: get(listingProduct, 'listingId'),
      price: removeCurrencySymbol(
        get(variant, 'price', `sizes[${activeSizeIndex}].price`)
      ),
      brand: storeName,
      category: productType,
      variant: `Color: ${get(variant, 'color')} | Size: ${get(
        variant,
        `sizes[${activeSizeIndex}].label`
      )}`,
      dimension8: get(stores, 'sellerId'),
      dimension9: get(listingProduct, 'listingId'),
      dimension13: get(stores, 'marketingPixels.gmcMerchantId')
    };
    useEffect(() => {
      if (listingProduct) {
        const eventBody = {
          currencyCode: currency,
          detail: {
            actionField: {
              list: otherProductClicked ? 'Other Products' : list
            },
            products: [eventProduct]
          }
        };
        pushEvent(PRODUCT_DETAIL, eventBody);
      }
    }, [productId, otherProductClicked, setOtherProductClicked]);

    const [{ _teespring_partner_attribution: partnerCookie }] = useCookies();
    const trackPartnersCookieInParams = tiktok.trackPartnersCookieInParams(partnerCookie);

    const checkoutFlow = get(listing, 'checkout_flow');
    const showPreorderForm = checkoutFlow && parseInt(checkoutFlow) === 1;

    const price = getPriceFromSize(variant, sizeId) || get(listingProduct, 'price');
    const priceElem = price && !showPreorderForm && <Price value={ price } />;

    const isOutOfStock = inventoryCount <= 0 ||
      !includes(
        map(get(variant, 'availableSizesWithId'), size => size.id),
        get(variant, `sizes[${activeSizeIndex}].id`)
      );

    const components = {};

    components.listingHeader = (
      <div className={ `listing-header__wrapper ${setClass({ default: 'mb4', mobileLg: 'mb2' })}` }>
        <div className="listing-header">
          <div>
            <h4 className="listing-header__title" data-cy="listing-title">
              {title}
            </h4>
            <p>{productType}</p>
          </div>
          <h2 className="listing-header__price">{priceElem}</h2>
        </div>
      </div>
    );
    // 2023 Holiday Message overrides any previous holiday messaging and displays only in December 2023
    components.holidayCopy = primaryProduct.productType !== '' ?
      (
        <HolidayMessage
          listingSlug={ listingProduct.url }
          activeProductIndex={ activeProductIndex }
        />
      ) : (<></>);

    components.productDetailTabs = (
      <ProductDetailTabs
        activeProductIndex={ activeProductIndex }
        productId={ productId }
        listingSlug={ listingProduct.url }
        description={ description }
        showPreorderForm={ showPreorderForm }
      />
    );

    components.physicalReturnDetails = (
      <PhysicalReturnDetails
        listingSlug={ listingProduct.url }
        activeProductIndex={ activeProductIndex }
      />
    );

    components.listingForm = (
      <ListingForm
        listing={ listing }
        listingProduct={ listingProduct }
        variant={ variant }
        activeVariationIndex={ activeVariationIndex }
        activeSizeIndex={ activeSizeIndex }
        itemGroupId={ itemGroupId }
        productId={ productId }
        showPreorderForm={ showPreorderForm }
        onProductVariationChange={ onProductVariationChange }
        price={ price }
        onFormSubmit={ onFormSubmit }
        onProductSizeChange={ onProductSizeChange }
        cartData={ cartData }
        primaryProduct={ primaryProduct }
        otherProductClicked={ otherProductClicked }
        eventProduct={ eventProduct }
        trackPartnersCookieInParams={ trackPartnersCookieInParams }
      />
    );

    const variantImage = get(variant, 'images[0].full');
    const priceStr = parseFloat(get(variant, 'price')).toLocaleString(locale, {
      style: 'currency',
      currency
    });
    const seoDescription = `Shop the ${listingProduct.title} for ${priceStr} at ${storeName}! See all the colors and styles at ${storeName}.`;

    const details = get(productDetails, productId, false);
    const sizeChartData = get(details, 'sizeChart', {});
    const sizeChartUrl = get(details, 'sizeChartUrl', '');

    const sizes = sizeChartData ? Object.keys(sizeChartData) : [];
    const dimensions = sizes.length ? Object.keys(get(sizeChartData, sizes[0])) : [];
    const measurements = sizes.length && dimensions.length ? Object.keys(get(sizeChartData, [sizes[0], dimensions[0]])) : [];

    return (
      <>
        <ModalConductor
          activeModal={ activeModal }
          setActiveModal={ dispatchSetActiveModal }
          modals={ [
            {
              id: 'sizechart-modal',
              node: (
                <SizeChartModal
                  sizeChartData={ sizeChartData }
                  sizeChartUrl={ sizeChartUrl }
                  sizes={ sizes }
                  dimensions={ dimensions }
                  measurements={ measurements }
                />
              )
            }
          ] }
        />
        <SEO
          title={ `${listingProduct.title} | ${storeName}` }
          description={ seoDescription }
          data={ {
            'og:type': 'product',
            'og:price:amount': priceStr,
            'og:price:currency': get(variant, 'currency'),
            'og:availability': isOutOfStock ? 'oos' : 'instock',
            'og:image': variantImage,
            'twitter:image': variantImage
          } }
        />
        <WrappedComponent { ...props } { ...components } />
      </>
    );
  };

  const {
    number, shape, func, object, string, node
  } = propTypes;

  BaseComponent.propTypes = {
    listing: object.isRequired,
    listingProduct: shape({
      title: string.isRequired,
      description: string.isRequired
    }).isRequired,
    cartData: shape({
      object
    }).isRequired,
    onProductSizeChange: func.isRequired,
    onProductVariationChange: func.isRequired,
    onFormSubmit: func.isRequired,
    activeSizeIndex: number.isRequired,
    activeVariationIndex: number.isRequired,
    activeProductIndex: number.isRequired,
    inventory: object.isRequired,
    productDetails: object.isRequired,
    dispatchFetchListingInventoryCount: func.isRequired,
    location: shape({
      pathname: string
    }).isRequired,
    trackItemView: func.isRequired,
    stores: object.isRequired,
    currency: string.isRequired,
    locale: string.isRequired,
    storeName: string.isRequired,
    dispatchSetActiveModal: func.isRequired,
    activeModal: shape({
      id: string,
      modal: node
    }).isRequired
  };

  const mapStateToProps = (state) => {
    return {
      inventory: state.inventory,
      productDetails: state.storeListings.productDetails,
      storeName: state.stores.name,
      currency: state.localizationData.buyer_currency,
      locale: state.localizationData.buyer_locale,
      activeModal: state.activeModal
    };
  };

  const mapDispatchToProps = dispatch => ({
    dispatchSetActiveModal: (id, props) => dispatch(setActiveModal(id, props))
  });

  return connect(mapStateToProps, mapDispatchToProps)(BaseComponent);
}

export default ListingDetailsWrapper;
